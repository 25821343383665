import http from "../https";

type TRequestBody = {
  workspace_id: number;
  entity_id: number;
  project_id: number;
  survey_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  viewType: string;
};

export const useGetDashboardData = (requestData: TRequestBody) =>
  http.dashboardAPIClient.post(`/dashboard/dashboard/overview`, requestData);
