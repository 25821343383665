import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { FC, useEffect } from "react";
import { MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import FlagIcon from "@mui/icons-material/Flag";
import EmptyLabel from "./EmptyLabel";
import GraphWrapper from "./graph/GraphWrapper";
import LoadingPopup from "./LoadingPopup/LoadingPopup";
import SetUpColumnsPopup from "./SetUpColumnsPopup/SetUpColumnsPopup";
import SetUpSegmentsPopup from "./SetUpSegmentsPopup/SetUpSegmentsPopup";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type HotspotsScreenProps = {};

const HotspotsScreen: FC<HotspotsScreenProps> = observer(() => {
  useEffect(() => {
    if (topStore.selectedSurvey && topStore.activeTab === "hotspots") {
      store.getQuestionsList(topStore.selectedSurvey.value);
      topStore.getSurveyResponseCount(topStore.selectedSurvey.value, []);
    }
  }, [topStore.selectedSurvey, topStore.activeTab]);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "hotspots" &&
      topStore.overviewBenchmark !== undefined &&
      store.questionsList &&
      topStore.role
    ) {
      store.getData(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
        topStore.overviewBenchmark
      );
    }
  }, [
    StoreLayout.currentWorkspaceId,
    StoreLayout.currentEntityId,
    topStore.selectedProject.id,
    topStore.selectedSurvey,
    store.isENPS,
    store.isFavourableScore,
    store.isOveralScore,
    topStore.overviewBenchmark,
    store.selectedSegments,
    store.questionsList,
    topStore.role,
  ]);

  return (
    <Container>
      <TopContainer>
        <Group>
          <TopContainerText>
            Viewing results for {topStore.calculateRespondentsCount} respondents
          </TopContainerText>
          <Divider>|</Divider>
          <TopContainerText>
            Last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
          </TopContainerText>
        </Group>
        <Group>
          {/* {topStore.benchmarkFilterList?.length > 0 && (
            <FilterBox>
              <StyledFlagIcon />
              <Text>Benchmark against:</Text>
              <StyledSelect
                id="benchmark_select"
                value={topStore.overviewBenchmark}
                onChange={(e: SelectChangeEvent<number>) =>
                  topStore.setData(e.target.value, "overviewBenchmark")
                }
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                IconComponent={KeyboardArrowDownIcon}
              >
                {topStore.benchmarkFilterList.map((el) => (
                  <StyledMenuItem key={el.value} value={el.value}>
                    {el.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FilterBox>
          )} */}
        </Group>
      </TopContainer>
      {topStore.isSurveyHaveResponseCount ? <GraphWrapper /> : <EmptyLabel />}
      <SetUpColumnsPopup />
      <LoadingPopup />
      <SetUpSegmentsPopup />
    </Container>
  );
});

export default HotspotsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px 16px 0px;
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 18px !important;
  margin-right: 4px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 140px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-color: var(--colorGrayForeground6) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 8px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px 8px 0px 4px;
  white-space: nowrap;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  font-weight: 400 !important;
`;

const StyledSwitch = styled(Switch)`
  max-width: 60px !important;
  margin-right: -12px;
`;
