import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { DASHBOARD_MENU, PROJECT_TYPES } from "constants/dashboard-menu";
import {
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import CustomButton from "components/button/Button";

type MenuProps = {
  isSticky: boolean;
};

const Menu: FC<MenuProps> = observer((props) => {
  const [selectedReport, setSelectedReport] = useState("");

  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  const reportTypes = [
    { label: "Download Excel (.xlsx)", value: "excel" },
    { label: "Download PDF (.pdf)", value: "pdf" },
  ];

  const formattedDate =
    String(new Date().getDate()).padStart(2, "0") +
    ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][
      new Date().getMonth()
    ] +
    new Date().getFullYear();

  const generateExcelFile = (filename) => {
    const csvContent = "";
    const blob = new Blob([csvContent], { type: "application/vnd.ms-excel" });
    const link = Object.assign(document.createElement("a"), {
      href: URL.createObjectURL(blob),
      download: filename,
    });
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const generateAndDownloadPDF = (content: string, fileName: string) => {
    const pdfHeader = "%PDF-1.4\n1 0 obj\n<< /Type /Catalog /Pages 2 0 R >>\nendobj\n";
    const pdfPage = "2 0 obj\n<< /Type /Pages /Kids [3 0 R] /Count 1 >>\nendobj\n";
    const pdfContent = `3 0 obj\n<< /Type /Page /Parent 2 0 R /Resources << >> /MediaBox [0 0 612 792] /Contents 4 0 R >>\nendobj\n`;
    const pdfText = `4 0 obj\n<< /Length ${
      content.length + 50
    } >>\nstream\nBT /F1 24 Tf 100 700 Td (${content}) Tj ET\nendstream\nendobj\n`;
    const pdfEnd =
      "xref\n0 5\n0000000000 65535 f \n0000000018 00000 n \n0000000077 00000 n \n0000000178 00000 n \n0000000351 00000 n \ntrailer\n<< /Size 5 /Root 1 0 R >>\nstartxref\n449\n%%EOF";

    const pdfFileContent = pdfHeader + pdfPage + pdfContent + pdfText + pdfEnd;

    const blob = new Blob([pdfFileContent], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  useEffect(() => {
    if (selectedReport) {
      switch (selectedReport) {
        case "pdf":
          return generateAndDownloadPDF(
            "",
            `${StoreLayout.currentEntityName}_${store.selectedSurvey.label}_report_${formattedDate}`
          );
        case "excel":
          return generateExcelFile(
            `${StoreLayout.currentEntityName}_${store.selectedSurvey.label}_report_${formattedDate}`
          );
      }

      setSelectedReport("");
    }
  }, [selectedReport]);

  const hasFeature = (menu: string): boolean => {
    if (menu === "overview") {
      return StorePermission.features.dashboard_overview.hasFeature;
    } else if (menu === "responses") {
      return StorePermission.features.dashboard_responses.hasFeature;
    } else if (menu === "hotspots") {
      return StorePermission.features.dashboard_hotspot.hasFeature;
    } else if (menu === "trend") {
      return StorePermission.features.dashboard_trends.hasFeature;
    } else if (menu === "prioritize") {
      return StorePermission.features.dashboard_prioritize.hasFeature;
    }
    return true;
  };

  const isDisableMenu = (tab: string) =>
    Boolean(
      store.selectedProject &&
        store.selectedProject.Type === PROJECT_TYPES.oneOff &&
        tab === "trend"
    );

  const changeMenu = (menuItem: { label: string; value: string }) =>
    !isDisableMenu(menuItem.value) && store.setData(menuItem.value, "activeTab");

  const handleExportSurveyData = () => {
    generateExcelFile(
      `${StoreLayout.currentEntityName}_${store.selectedSurvey.label}_raw_responses_${formattedDate}`
    );
  };

  return (
    <Container $isSticky={props.isSticky}>
      <MenuList>
        {DASHBOARD_MENU.map((menuItem, index, array) => {
          if (!hasFeature(menuItem.value)) {
            return;
          }
          return (
            <CustomWidthTooltip
              key={index}
              title={`This survey is a one-off survey. Trend results are only available for frequency and milestone surveys.`}
              placement="top"
              arrow
              disableHoverListener={!isDisableMenu(menuItem.value)}
            >
              <CustomMenuItem
                $isDisabled={isDisableMenu(menuItem.value)}
                $first={index === 0}
                $last={index === array.length - 1}
                $active={store.activeTab === menuItem.value}
                onClick={() => changeMenu(menuItem)}
              >
                {menuItem.label}
              </CustomMenuItem>
            </CustomWidthTooltip>
          );
        })}
        
        {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) && (
          <PSDButtonsWrapper>
            <CustomButton
              name="AlertButtonYes"
              color="primary"
              variant="outlined"
              style={{ backgroundColor: "var(--colorNeutralBackground1)" }}
              disabled={!store.isSurveyHaveResponseCount}
              onClick={handleExportSurveyData}
            >
              Export survey data
            </CustomButton>

            <StyledSelect
              id="download_report"
              value={selectedReport}
              onChange={(e: SelectChangeEvent<string>) => setSelectedReport(e.target.value)}
              displayEmpty
              disabled={!store.isSurveyHaveResponseCount}
              variant="standard"
            >
              {!selectedReport && (
                <MenuItem value="" disabled>
                  <em>Download Report</em>
                </MenuItem>
              )}
              {reportTypes.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </PSDButtonsWrapper>
        )}
      </MenuList>
    </Container>
  );
});

export default Menu;

const Container = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  border-bottom: 1px solid var(--graySelectBoxBorder);
  transition: all 0.3s;
  z-index: 15;
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CustomMenuItem = styled.span<{
  $isDisabled: boolean;
  $active?: boolean;
  $first?: boolean;
  $last?: boolean;
}>`
  margin: ${(props) =>
    props.$first ? `0px 12px 0px 0px` : props.$last ? `0px 0px 0px 12px` : `0px 12px`};
  padding: 12px 0px;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.$isDisabled
      ? "var(--colorNeutralForeground5, #959ba2)"
      : props.$active
      ? `var(--colorBrandForeground1)`
      : `var(--colorPaletteVioletBackground1)`};
  ${(props) => props.$active && `border-bottom: 4px solid var(--colorBrandForeground1)`};
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

//-- PSD Demo
const PSDButtonsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 12px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 186px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: 1px solid rgba(25, 118, 210, 0.5) !important;
  color: #1976d2 !important;

  .MuiSelect-icon {
    color: #1976d2 !important;
  }

  &.Mui-disabled {
    .MuiSelect-icon {
      color: #c5c5c5 !important;
    }

    border-color: #e4e4e5 !important;    

    .MuiSelect-root em,
    .MuiSelect-select em {
      color: #c5c5c5 !important;
      opacity: 0.7;
    }
  }

  .MuiSelect-standard {
    padding-left: 8px;
    border-bottom: none;
  }

  &.MuiInput-underline:before,
  &.MuiInput-underline:after,
  &.MuiInput-underline:hover:not(.Mui-disabled):before,
  &.MuiInput-underline:focus:before,
  &.MuiInput-underline.Mui-focused:after {
    border-bottom: none !important;
  }

  &:focus {
    outline: none;
  }

  em {
    font-family: Roboto, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    color: #1976d2 !important;
    font-style: normal !important;
  }
`;
//--
