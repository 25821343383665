import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { Backdrop, Checkbox, CircularProgress, FormControlLabel } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import Pagination from "components/pagination/Pagination";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TColumn, TEmployee } from "types/recipients";
import CustomTableRecipients from "./CustomTableRecipients";
import RemoveRecipientsPopup from "../MillstoneRecipientShedule/RemoveRecipientsPopup";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type ExportRecipientsTableProps = {};

const ExportRecipientsTable: FC<ExportRecipientsTableProps> = observer(() => {
  const [sortModel, setSortModel] = useState({ field: null, sort: null });
  const [activePage, setActivePage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [noDataTitle, setNoDataTitle] = useState("");
  const [modalRemove, setModalRemove] = useState(false);
  const [loading, setLoading] = useState(false);

  const widthMediaScreen = 1450;
  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  React.useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth > widthMediaScreen) {
        setNoDataTitle("Add employees from the left to begin");
      } else {
        setNoDataTitle("Add employees from the top to begin");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const removeHandler = () => {
    if (store.is_running_survey) {
      setModalRemove(true);
    } else {
      store.removeRecipients();
    }
  };

  const selectionChangeHandler = (isSelected?: boolean, row?: TEmployee) => {
    store.changeSelectedEmploees(isSelected, row);
  };

  const changeSort = (field: string, sort: string) => {
    store.setData("exportSortField", field);

    var order = false;
    if (sort === "desc") {
      order = true;
    }

    store.setData("exportSortOrder", order);
    store.getAllRecipients();
  };

  const columns = [
    {
      width: 20,
      render: (row: TEmployee) => {
        const isSelected = store.exportSelectionRows.includes(row.id);
        return (
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                <StyledCheckBox
                  id={"Survey_EditCreate_Recipietns_Export_Table_CheckBox_" + row.id}
                  checked={isSelected}
                />
              }
              label={false}
            />
          </CheckBoxContainer>
        );
      },
    },
    {
      id: "full_name",
      label: (
        <StyledContainerLabelColumn>
          <p id="Survey_EditCreate_Recipietns_Export_Table_FullName_Text">FULL NAME</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="Survey_EditCreate_Recipietns_Export_Table_FullName_ArrowUp"
              style={
                sortModel.field === "full_name" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "asc")}
            />
            <KeyboardArrowDownIconStyle
              id="Survey_EditCreate_Recipietns_Export_Table_FullName_ArrowDown"
              style={
                sortModel.field === "full_name" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "preferred_name",
      label: (
        <span id="Survey_EditCreate_Recipietns_Export_Table_PreferredName">PREFERRED NAME</span>
      ),
    },
    {
      id: "email",
      label: (
        <StyledContainerLabelColumn>
          <p id="Survey_EditCreate_Recipietns_Export_Table_Email_Text">EMAIL ADDRESS</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="Survey_EditCreate_Recipietns_Export_Table_Email_ArrowUp"
              style={
                sortModel.field === "email" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "asc")}
            />
            <KeyboardArrowDownIconStyle
              id="Survey_EditCreate_Recipietns_Export_Table_Email_ArrowDown"
              style={
                sortModel.field === "email" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "trigger_recipient",
      label: (
        <StyledContainerLabelColumn>
          <p>TRIGGER</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              style={
                sortModel.field === "trigger_recipient" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("trigger_recipient", "asc")}
            />
            <KeyboardArrowDownIconStyle
              style={
                sortModel.field === "trigger_recipient" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("trigger_recipient", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
  ] as TColumn[];

  const handleSelectAll = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const totalPages = Math.ceil(store.exportCountTotal / store.exportCountPerPage);

    store.setData("selectAllEmployees", checked);

    if (!checked) {
      store.setData("exportSelectionRows", []);
      return;
    }

    if (totalPages > 1) {
      setLoading(true);
      const pagePromises = Array.from({ length: totalPages }, (_, i) =>
        store.fetchEmployeesNoRecipients({ page: i + 1 })
      );

      const responses = await Promise.all(pagePromises);
      setLoading(false);

      const allNewIds = responses.flatMap((response) => {
        if (response && response.data && Array.isArray(response.data.recipients)) {
          return response.data.recipients
            .filter((item) => !store.exportSelectionRows.includes(item.id))
            .map((item) => item.id);
        }

        return [];
      });

      store.setData("exportSelectionRows", [...store.exportSelectionRows, ...allNewIds]);
    } else {
      const selectedIds = checked
        ? store.exportRecipientsList.map((item: TEmployee) => item.id)
        : [];

      store.setData("exportSelectionRows", selectedIds);
    }
  };

  return (
    <Container>
      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TableHeader>
        <CheckBoxContainer>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id="Survey_EditCreate_Recipietns_Export_Table_SelectAll_CheckBox"
                disabled={store.exportRecipientsList.length === 0}
                checked={store.selectAllEmployees}
                onChange={(e) => handleSelectAll(e)}
              />
            }
            label={
              <span id="Survey_EditCreate_Recipietns_Export_Table_SelectAll_CheckBox_Label">
                Select all
              </span>
            }
          />
        </CheckBoxContainer>

        {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) ? (
          <PSDRemoveButton
            id="Survey_EditCreate_Recipietns_Export_Table_RemoveFromlist_Button"
            variant="contained"
            disabled={store.exportSelectionRows.length === 0}
            onClick={removeHandler}
          >
            {`< Remove from survey (${store.exportSelectionRows.length})`}
          </PSDRemoveButton>
        ) : (
          <AddButton
            id="Survey_EditCreate_Recipietns_Export_Table_RemoveFromlist_Button"
            disabled={store.exportSelectionRows.length === 0}
            onClick={removeHandler}
          >
            {`< Remove from list (${store.exportSelectionRows.length})`}
          </AddButton>
        )}
      </TableHeader>
      <div style={{ width: "100%" }}>
        <CustomTableRecipients
          data={store.exportRecipientsList}
          columns={columns}
          changeHandler={selectionChangeHandler}
          variant="removeRecipient"
          noDataTitle={{
            description: noDataTitle,
          }}
          id="ExportRecipients_"
        />
      </div>
      <Pagination
        id="Survey_EditCreate_Recipietns_Export_Table_"
        activePage={store.exportActivePage}
        itemsCountPerPage={store.exportCountPerPage}
        onChange={(value) => {
          store.setExportActivePage(value);
          store.getAllRecipients();
        }}
        pageRangeDisplayed={store.exportCountPerPage}
        totalItemsCount={store.exportCountTotal}
        totalPages={store.exportCountTotal / store.exportCountPerPage}
        setCount={(value) => {
          store.setExportActivePage(1);
          store.setExportCountPerPage(Number(value));
          store.getAllRecipients();
        }}
      />
      <RemoveRecipientsPopup
        open={modalRemove}
        hide={() => setModalRemove(false)}
        save={() => {
          store.removeRecipients();
          setModalRemove(false);
        }}
      />
    </Container>
  );
});

export default ExportRecipientsTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media (max-width: 1450px) {
    max-width: 100%;
  }
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const AddButton = styled(CustomButton)`
  width: fit-content !important;
  padding: 0px !important;
  justify-content: flex-start !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  :disabled {
    text-decoration: none !important;
    svg {
      color: var(--colorNeutralForeground5) !important;
    }
  }
`;

const CheckBoxContainer = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--grenBackgroundColor2);
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 0px, 0px, 1px, 0px;
`;

//--PSD demo
const PSDRemoveButton = styled(CustomButton)`
  width: fit-content !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  justify-content: flex-start !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  :disabled {
    text-decoration: none !important;
    svg {
      color: var(--colorNeutralForeground5) !important;
    }
  }
`;
//---
