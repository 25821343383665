import React, { FC, useState } from "react";
import { sm_survey } from "types/projects";
import styled from "styled-components";
import CustomTable from "./components/CustomTable";
import { SelectIcon } from "./components/SelectIcon";
import { Menu, MenuItem } from "@mui/material";
import store from "./store";
import { observer } from "mobx-react";
import PreviewIcon from "features/Templates/CommunicationTemplates/PreviewIcon";
import { useNavigate } from "react-router";
import { SURVEY_STATUS } from "constants/survey-status";
import dayjs from "dayjs";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import shortStringLength from "components/shortStringLength/shortStringLength";
import storeCreation from "../SurveyCreation/store";

type ProjectsTableProps = {};

const ProjectsTable: FC<ProjectsTableProps> = observer(() => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedRow, setSelectedRow] = useState<sm_survey | null>(null);

  const hasFeature = (): boolean => {
    return (
      (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.OneOff &&
        StorePermission.features.one_off.hasFeature) ||
      (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones &&
        StorePermission.features.milestone.hasFeature) ||
      (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Frequency &&
        StorePermission.features.frequency.hasFeature)
    );
  };

  const tableData = {
    headers: [
      {
        title: "SURVEY NAME",
        value: "name",
        filter: false,
        sort: true,
        renderRow: (row: sm_survey) => (
          <TableCellWrapper $minWidth={200}>
            <TableName id={`Survey_List_Table_name_${row.id}`}>
              {shortStringLength(row.name)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "DAY",
        value: "milestone_day",
        filter: false,
        sort: false,
        not_visible: store.project?.projecttype_code !== "milestones",
        renderRow: (row: sm_survey) => (
          <TableCellWrapper>
            <TableName id={`Survey_List_Table_Milestone_Day_${row.id}`}>
              {row.milestone_day}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "SURVEY STATUS",
        value: "status_idNavName",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => (
          <TableCellWrapper>
            <TableName id={`Survey_List_Table_Status_IdNavName_${row.id}`}>
              {row.status_idCode !== SURVEY_STATUS.upcoming || row.status_idCode !== SURVEY_STATUS.unscheduled ? (
                <RunningSurveyChip $status={row.status_idCode}>{row.status_idNavName}</RunningSurveyChip>
              ) : (
                row.status_idNavName
              )}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "OVERALL SCORE",
        value: "score",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => (
          <TableCellWrapper>
            <TableName id={`Survey_List_Table_Score_${row.id}`}>
              {row.status_idCode === SURVEY_STATUS.upcoming ||
                row.status_idCode === SURVEY_STATUS.unscheduled ? (
                " - "
              ) : (
                <PreviewColumn>
                  <span>{row.score} %</span>
                  <LinkPreview href={`/dashboard?project_id=${row.project_id}&survey_id=${row.id}`}>
                    <LinkPreviewText>View results</LinkPreviewText>
                  </LinkPreview>
                </PreviewColumn>
              )}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "QUESTIONS",
        value: "count_questions",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => {
          return (
            <TableCellWrapper>
              <TableName id={`Survey_List_Table_Count_Questions_${row.id}`}>
                <PreviewColumn>
                  <span>{row.count_questions}</span>
                  {row.status_idCode !== SURVEY_STATUS.complete && (
                    <LinkPreview
                      id={`Survey_List_Table_Count_Questions_Link_${row.id}`}
                      $disabled={
                        !hasFeature() ||
                        !(
                          StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
                          StorePermission.projectPermissions.createAndEditProject.hasPermission
                        )
                      }
                      target={row.status_idCode === SURVEY_STATUS.running ? "_blank" : null}
                      href={
                        row.status_idCode === SURVEY_STATUS.running
                          ? `/survey-preview?idSurvey=${row.code}`
                          : `/survey?id=${row.id}`
                      }
                    >
                      {row.status_idCode === SURVEY_STATUS.running && <PreviewIcon />}
                      {row.status_idCode === SURVEY_STATUS.running ? (
                        "Preview"
                      ) : row.status_idCode === SURVEY_STATUS.historical ? "" : (
                        <LinkPreviewText
                          id={`Survey_List_Table_Count_Questions_Link_Text_${row.id}`}
                          onClick={() => localStorage.setItem("surveyAction", "edit")}
                        >
                          Edit
                        </LinkPreviewText>
                      )}
                    </LinkPreview>
                  )}
                </PreviewColumn>
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "PARTICIPATION",
        value: "participation",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => (
          <TableCellWrapper>
            {row?.status_idCode === SURVEY_STATUS.historical ? "-" : (
              <TableName id={`Survey_List_Table_Participation_${row.id}`}>
                {row.count_participation_done} / {row.count_participation}
              </TableName>
            )}
          </TableCellWrapper>
        ),
      },
      {
        title: "TAGS",
        value: "tags",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => (
          <TableCellWrapper id={`Survey_List_Table_Wrapper_Tags_${row.id}`}>
            <TableName id={`Survey_List_Table_Tags_${row.id}`}>
              {shortStringLength(row.tag_names)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "LAST UPDATE",
        value: "strupdated_at",
        filter: false,
        sort: true,
        renderRow: (row: sm_survey) => {
          const da = dayjs(row.updated_at);
          const survey_date_update = da.toDate();
          const formattedUpdateDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(survey_date_update);

          const created = dayjs(row.created_at);
          const survey_date_create = created.toDate();
          const formattedCreatedDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(survey_date_create);

          return (
            <TableCellWrapper>
              <TableName id={`Survey_List_Table_Strupdated_At_${row.id}`}>
                <UpdatedBlock>
                  <UpdatedDiv id={`Survey_List_Table_Strupdated_At_UpdateDate_${row.id}`}>
                    {row.updated_at === null ? "" : formattedUpdateDate}
                  </UpdatedDiv>
                  <CreatedDiv id={`Survey_List_Table_Strupdated_At_CreatedDate_${row.id}`}>
                    {row.created_at === null ? "" : `Created on ${formattedCreatedDate}`}
                  </CreatedDiv>
                </UpdatedBlock>
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "SENDING NEXT COMMS",
        value: "comments",
        filter: false,
        sort: false,
        not_visible: store.project?.projecttype_code === "milestones",
        renderRow: (row: sm_survey) => {
          if (row.sending_next_comms === null) {
            return (
              <TableCellWrapper id={`Survey_List_Table_Wrapper_Comments_${row.id}`}>
                <TableName id={`Survey_List_Table_Comments_${row.id}`}></TableName>
              </TableCellWrapper>
            );
          }

          const created = dayjs(row.sending_next_comms);
          const survey_date_send = dayjs(created).toDate();
          const formatted_next_send = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(survey_date_send);

          return (
            <TableCellWrapper>
              <TableName id={`Survey_List_Table_Comments_${row.id}`}>
                {formatted_next_send}
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "",
        value: "id",
        filter: false,
        sort: false,
        renderRow: (row: sm_survey) => (
          <TableCellWrapperEnd>
            <RevokeAdminBtn
              id={`Survey_List_Table_Option_Button_${row.id}`}
              onClick={(e) => {
                setSelectedRow(row);
                handleClick(e);
              }}
            >
              <SelectIcon />
            </RevokeAdminBtn>
          </TableCellWrapperEnd>
        ),
      },
    ],
  };

  return (
    <>
      <CustomTable
        list={store.data}
        sort={store.sortField}
        hideAddButton={false}
        sortType={store.sortType}
        data={tableData}
        changeSort={(s: string, t: string) => store.changeSort(s, t)}
        id={"Surveylist_"}
      />

      <Menu
        anchorEl={anchorEl}
        id="Survey_List_OptionMenu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id="Survey_List_OptionMenu_ViewResults"
          disabled={!hasFeature() || selectedRow?.status_idCode === SURVEY_STATUS.historical}
          onClick={() => store.getSurveyResult(selectedRow.id)}
        >
          View results
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_PreviewSurvey"
          disabled={selectedRow?.status_idCode === SURVEY_STATUS.historical}
          onClick={() => navigate(`/survey-preview?idSurvey=${selectedRow.code}`)}
        >
          Preview survey
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_EditSurvey"
          disabled={
            !hasFeature() ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )
            || selectedRow?.status_idCode === SURVEY_STATUS.historical
          }
          onClick={() => {
            if (selectedRow.status_idCode === SURVEY_STATUS.running) {
              storeCreation.openRunningSurveyEditPopup = true;
              storeCreation.idProject = selectedRow.project_id;
            }
            navigate(`/survey?id=${selectedRow.id}`);
            localStorage.setItem("surveyAction", "edit");
          }}
        >
          Edit survey
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_EditRecipients"
          disabled={
            !hasFeature() ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )
            || selectedRow?.status_idCode === SURVEY_STATUS.historical
          }
          onClick={() => {
            if (selectedRow.status_idCode === SURVEY_STATUS.running) {
              storeCreation.openRunningSurveyEditPopup = true;
              storeCreation.idProject = selectedRow.project_id;
            }
            navigate(`/recipients?id=${selectedRow.id}`);
            localStorage.setItem("surveyAction", "edit");
          }}
        >
          Edit recipients
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_EditCommunications"
          disabled={
            !hasFeature() ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )
            || selectedRow?.status_idCode === SURVEY_STATUS.historical
          }
          onClick={() => {
            if (selectedRow.status_idCode === SURVEY_STATUS.running) {
              storeCreation.openRunningSurveyEditPopup = true;
              storeCreation.idProject = selectedRow.project_id;
            }
            navigate(`/communications?id=${selectedRow.id}`);
            localStorage.setItem("surveyAction", "edit");
          }}
        >
          Edit communications
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_Duplicate"
          disabled={
            !hasFeature() ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )
            || selectedRow?.status_idCode === SURVEY_STATUS.historical
          }
          onClick={() => {
            store.duplicateSurveyClick(selectedRow);
            handleClose();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_SaveAsTemplate"
          disabled={!hasFeature() || selectedRow?.status_idCode === SURVEY_STATUS.historical ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )}
          onClick={() => {
            store.openClickSaveAsTempPopup(true);
            store.passIdToPopup(selectedRow);
            handleClose();
          }}
        >
          Save as template
        </MenuItem>
        <MenuItem
          id="Survey_List_OptionMenu_Delete"
          disabled={!hasFeature() ||
            !(
              StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
              StorePermission.projectPermissions.createAndEditProject.hasPermission
            )}
          onClick={() => {
            store.openClickSurveyDeletePopup(true);
            store.passIdToPopup(selectedRow);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
});

const RevokeAdminBtn = styled.button`
  border: none;
  margin-right: 10px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const TableCellWrapper = styled.div<{ $minWidth?: number }>`
  display: flex;
  min-height: 30px;
  min-width: ${(props) => (props.$minWidth ? `${props.$minWidth}px` : "100px")};
  justify-content: flex-start;
  align-items: center;
`;

const RunningSurveyChip = styled.div<{ $status?: string }>`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 2px;
  background: ${(props) => (props.$status === SURVEY_STATUS.historical ? "var(--colorPaletteYellowForeground1)" :
    props.$status === SURVEY_STATUS.running ? "var(--colorPaletteGreenBackground8)" :
      props.$status === SURVEY_STATUS.complete ? "var(--colorNeutralBackground3)" :
        "var(--#F9FAFC)")};
  padding: 2px 5px;
`;

const TableCellWrapperEnd = styled.div`
  display: flex;
  min-height: 30px;
  min-width: 100px;
  justify-content: flex-end;
  align-items: center;
`;

const LinkPreviewText = styled.span`
  margin-left: 1px;
`;

const LinkPreview = styled.a<{ $disabled?: boolean }>`
  margin-left: 20px;
  color: ${(props) =>
    props.$disabled
      ? "var(--colorNeutralForeground5, #959ba2)"
      : "var(--colorBrandForeground1, #1477F8)"};
  font-family: Roboto, sans-serif;
  font-size: 14px;
  pointer-events: ${(props) => props.$disabled && "none"};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const PreviewColumn = styled.div`
  display: flex;
  align-items: center;
`;

const TableName = styled.div`
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const UpdatedBlock = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--colorPaletteVioletBackground1);
  margin: 0;
`;

const UpdatedDiv = styled.p`
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CreatedDiv = styled.div`
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export default ProjectsTable;
