import { ROLE_LIST } from "constants/role-list";
import { DIMENSION } from "constants/dashboard-overview";
import { useGetProjects } from "api/dasboard/useGetProjects";
import { useGetDashboardEnps } from "api/dasboard/useGetDashboardEnps";
import { useGetBenchmarkList } from "api/dasboard/useGetBenchmarkList";
import { useGetSurveyDashboard } from "api/dasboard/useGetSurvey";
import { useGetDashboardTrends } from "api/dasboard/useGetDashboardTrends";
import { DASHBOARD_MENU_VALUES } from "constants/dashboard-menu";
import { useGetDashboardSegments } from "api/dasboard/useGetDashboardSegments";
import { getSurveyAttributeAsync } from "api/dasboard/useGetSurveyAttribute";
import { getProjectAttributeAsync } from "api/dasboard/useGetProjectAttribute";
import { useGetDimensionsQuestion } from "api/dasboard/useGetDimensionsQuestion";
import { useGetQuestionBySurveyId } from "api/dasboard/useGetQuestionBySurveyId";
import { useGetDashboardDimensions } from "api/dasboard/useGetDashboardDimensions";
import { action, computed, reaction } from "mobx";
import { useGetDashboardOveralScore } from "api/dasboard/useGetDashboardOveralScore";
import { getSurveyResponseCountAsync } from "api/dasboard/useGetSurveyResponseCount";
import { useGetDashboardParticipation } from "api/dasboard/useGetDashboardParticipation";
import { makeAutoObservable, runInAction } from "mobx";
import { useGetDashboardOpenEndedQuestions } from "api/dasboard/useGetDashboardOpenEndedQuestions";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";
import { TDasbboardFilter, TDashboardGlobalFilter, TProject, TProjectInfo } from "types/dashboard";

import dayjs from "dayjs";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { useGetDashboardData } from "api/dasboard/useGetDashboardData";

class Store {
  projectInfo: TProjectInfo = null;

  activeTab: string = "overview";

  isSurveyProjectsPending = false;
  isSurveyPending = false;
  isOveralScorePending = false;
  isEnpsPending = false;
  isDimensionsPending = false;
  isParticipationLoading = false;
  isDimensionQuestionsPending = false;
  isTrendsPending = false;
  isSegmentsPendign = false;
  isOpenEndPending = false;
  isSurveyResponseCountPending = false;
  isSureyAttributesPending = false;
  isProjectAttributesPending = false;
  isSurveyQuestionsListPending = false;
  isBenchmarkListPending = false;
  isDashboardDataPending = false;

  responsesSelectedSentiment: string = "";
  isShowSentiment: boolean = false;

  benchmarkFilterList: { value: number; label: string }[] = [];

  overviewBenchmark: number = undefined;
  filters: TDasbboardFilter[] = [
    {
      unit_or_attr: null,
      segments: [],
    },
  ];

  filterAttributesList: TDashboardGlobalFilter[] = [];
  filterSegmentsList: { label: string; value: number }[] = [];

  overviewSegmentsCardFilter: number = 0;
  selectedOverviewSegmentsCardFilter: string = null;
  overviewTrendCardFilter: number = 0;
  overviewTrendCardDimenssionTypeList: { label: string; value: number }[] = [];
  selectedOverviewTrendCardDimenssionType: string = null;

  surveyProjectsList: TProject[] = [];
  surveyList = [];

  selectedProject: TProject = null;
  selectedSurvey: {
    label: string;
    value: number;
    status: string;
    threshold: number;
    isFirst: boolean;
  } = null;

  statusIdNavName: string = "";

  selectedResponsesOpenEndedQuestion: number = null;

  isOpenFiltersPopup: boolean = false;

  questionSelectedOpenEndedCard: number = null;
  openEndedTopics: number = 0;
  openEndedTopicsValid: number = 0;

  surveyQuestionsList = [];

  isSurveyHaveResponseCount: boolean = false;
  isDisplayOpenEndedCard: boolean = false;
  isDisplayTrends: boolean = true;

  isResponseScoredTabDisplay: boolean = true;
  isResponseOPenEndedTabDisplay: boolean = true;
  isResponseOthersTabDisplay: boolean = true;
  isNotOnlyOpenendQuestions: boolean = false;

  isOveralScoreEmpty: boolean = false;
  isENPSEmpty: boolean = false;
  isDimensionsEmpty: boolean = false;
  isSegmentsEmpty: boolean = false;

  recipientsCount: number = 0;
  respondentCount: number = 0;

  role: string = "ADMIN";
  secondaryRole = "ROLL_UP_TEAM";

  isAllCompaniesBenchmark: boolean = true;

  hotspotsViewingAs: string = "admin";

  responsesActiveLocalTab: string = "scored_questions";

  lastUpdated: string = `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"
    } ${dayjs().format("A")}`;

  constructor() {
    makeAutoObservable(this, {
      calculateRespondentsCount: computed,
      isPending: computed,
      isSegmentsThresholdShown: computed,
      isOpenEndedShown: computed,
      clearFilters: action,
    });

    reaction(
      () => [StoreLayout.currentEntityId, StoreLayout.currentWorkspaceId],
      () => this.clearFilters()
    );

    reaction(
      () => [
        this.activeTab,
        this.selectedSurvey,
        this.isResponseScoredTabDisplay,
        this.isResponseOPenEndedTabDisplay,
        this.isResponseOthersTabDisplay,
      ],
      () => {
        if (this.activeTab === DASHBOARD_MENU_VALUES.responses) {
          if (this.isResponseScoredTabDisplay) {
            this.responsesActiveLocalTab = "scored_questions";
          } else if (this.isResponseOPenEndedTabDisplay) {
            this.responsesActiveLocalTab = "open_ended";
          } else {
            this.responsesActiveLocalTab = "others";
          }
        }
      }
    );
  }

  get isSegmentsThresholdShown() {
    return Boolean(
      store.filters.find((filter: TDasbboardFilter) => Boolean(filter.segments.length > 1))
    );
  }

  get isOpenEndedShown() {
    return Boolean(
      this.isDisplayOpenEndedCard &&
      this.surveyQuestionsList?.length > 0 &&
      this.projectInfo?.openEndQuestion?.length > 0
    );
  }

  get isPending() {
    return Boolean(
      this.isSurveyProjectsPending ||
      this.isSurveyPending ||
      this.isOveralScorePending ||
      this.isEnpsPending ||
      this.isDimensionsPending ||
      this.isParticipationLoading ||
      this.isDimensionQuestionsPending ||
      this.isTrendsPending ||
      this.isSegmentsPendign ||
      this.isOpenEndPending ||
      this.isSurveyResponseCountPending ||
      this.isSureyAttributesPending ||
      this.isProjectAttributesPending ||
      this.isSurveyQuestionsListPending ||
      this.isBenchmarkListPending
    );
  }

  get calculateRespondentsCount() {
    if (this.respondentCount === this.recipientsCount && this.isSurveyHaveResponseCount)
      return "all";

    return this.respondentCount;
  }

  clearFilters() {
    this.filters = [
      {
        unit_or_attr: null,
        segments: [],
      },
    ];
  }

  redirectOpenEnded = () => {
    this.activeTab = "responses";
    this.responsesActiveLocalTab = "open_ended";
  };

  setData = (value: any, field: string) => {
    this[field] = value;
  };

  getDimensionsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isDimensionsPending = true;
      const response = await useGetDashboardDimensions({
        survey_id,
        attributes: filters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, dimensions: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isDimensionsPending = false;
    }
  };

  getParticipationData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    this.isParticipationLoading = true;
    try {
      const response = await useGetDashboardParticipation({
        survey_id,
        attributes: filters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, participation: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isParticipationLoading = false;
    }
  };

  getSegmentsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isSegmentsPendign = true;
      const response = await useGetDashboardSegments({
        survey_id,
        attributes: filters,
        segmentFilter: {
          type: this.overviewSegmentsCardFilter,
          conditional:
            this.overviewSegmentsCardFilter !== DIMENSION
              ? null
              : this.selectedOverviewSegmentsCardFilter,
        },
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, segments: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isSegmentsPendign = false;
    }
  };

  getEnpsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isEnpsPending = true;
      const response = await useGetDashboardEnps({
        survey_id,
        attributes: filters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, enps: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isEnpsPending = false;
    }
  };

  getOveralScoreData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isOveralScorePending = true;
      const response = await useGetDashboardOveralScore({
        survey_id,
        attributes: filters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, overalScore: response.data };
          this.isOveralScorePending = false;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
      this.isOveralScorePending = false;
    }
  };

  updateDashboardPendingStatuses = (val: boolean) => {
    this.isParticipationLoading = val;
    this.isOveralScorePending = val;
    this.isEnpsPending = val;
  };

  getDashboardData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.updateDashboardPendingStatuses(true);
      const response = await useGetDashboardData({
        workspace_id: StoreLayout.currentWorkspaceId,
        project_id: this.selectedProject.id,
        entity_id: StoreLayout.currentEntityId,
        survey_id,
        attributes: filters,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { 
            ...this.projectInfo,
            participation: response.data?.participation,
            // TODO: fix typos across whole project
            overalScore: response.data?.overallScore,
            enps: response.data?.enps
          };
          this.updateDashboardPendingStatuses(false);
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
      this.updateDashboardPendingStatuses(false);
    }
  };

  getTrendsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isTrendsPending = true;
      const response = await useGetDashboardTrends({
        survey_id,
        attributes: filters,
        segmentFilter: {
          type: this.overviewTrendCardFilter,
          conditional:
            this.overviewTrendCardFilter !== DIMENSION
              ? null
              : this.selectedOverviewTrendCardDimenssionType,
        },
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, trends: response.data };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isTrendsPending = false;
    }
  };

  getOpenEndedQuestionsData = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isOpenEndPending = true;
      const response = await useGetDashboardOpenEndedQuestions({
        survey_id,
        attributes: filters,
        questionId: Number(this.questionSelectedOpenEndedCard),
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.projectInfo = { ...this.projectInfo, openEndQuestion: response.data?.data || [] };
          this.openEndedTopics = response.data.topics;
          this.openEndedTopicsValid = response.data.validTopics;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isOpenEndPending = false;
    }
  };

  getSurveyProjects = async (
    project_id: string,
    setQueryParams: (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => void
  ) => {
    try {
      this.isSurveyProjectsPending = true;
      const response = await useGetProjects(StoreLayout.currentEntityId);
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.surveyProjectsList = response.data.map((el) => ({
            label: el.name,
            value: el.id,
            ...el,
          }));
        });
        runInAction(() => {
          if (project_id && response.data.some((project) => project.id === Number(project_id))) {
            this.selectedProject = response.data.find(
              (project) => project.id === Number(project_id)
            );
          } else {
            setQueryParams({ project_id: String(response.data[0].id) });
            this.selectedProject = response.data[0];
          }
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isSurveyProjectsPending = false;
    }
  };

  getSurvey = async (
    project_id: number,
    survey_id: string,
    setQueryParams: (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions
    ) => void
  ) => {
    try {
      this.isSurveyPending = true;
      const response = await useGetSurveyDashboard(project_id);
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.surveyList = response.data.map((el) => ({
            label: el.name,
            value: el.id,
            status: el.status_idNavName,
            threshold: el.threshold,
            isFirst: el.isFirst,
          }));
        });
        runInAction(() => {
          if (survey_id && response.data.some((survey) => survey.id === Number(survey_id))) {
            const survey = response.data.find((survey) => survey.id === Number(survey_id));
            this.selectedSurvey = {
              label: survey.name,
              value: survey.id,
              status: survey.status_idNavName,
              threshold: survey.threshold,
              isFirst: survey.isFirst,
            };
            localStorage.setItem(
              "dashboardActiveSurvey",
              JSON.stringify({
                projectName: this.selectedProject.name,
                surveyName: survey.name,
                status: survey.status_idNavName,
              })
            );
          } else {
            this.selectedSurvey = {
              label: response.data[0].name,
              value: response.data[0].id,
              status: response.data[0].status_idNavName,
              threshold: response.data[0].threshold,
              isFirst: response.data[0].isFirst,
            };
            setQueryParams({
              project_id: String(project_id),
              survey_id: String(response.data[0].id),
            });
            localStorage.setItem(
              "dashboardActiveSurvey",
              JSON.stringify({
                projectName: this.selectedProject.name,
                surveyName: response.data[0].name,
                status: response.data[0].status_idNavName,
              })
            );
          }
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isSurveyPending = false;
    }
  };

  getSurveyAttributes = async (workspace_id: number, entity_id: number, project_id: number, survey_id: number) => {
    try {
      this.isSureyAttributesPending = true;
      const response = await getSurveyAttributeAsync(workspace_id, entity_id, project_id, survey_id);
      this.filterAttributesList = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.isSureyAttributesPending = false;
    }
  };

  getProjectAttributes = async (project_id: number) => {
    try {
      this.isProjectAttributesPending = true;
      const response = await getProjectAttributeAsync(project_id);
      this.filterAttributesList = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.isProjectAttributesPending = false;
    }
  };

  changeFilters = (filters: TDasbboardFilter[]) => {
    this.filters = filters;
    this.isSurveyHaveResponseCount = false;
  };

  getDimensionQuestions = async (selectedSurvey: number) => {
    try {
      this.isDimensionQuestionsPending = true;
      const response = await useGetDimensionsQuestion(selectedSurvey);
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.overviewTrendCardDimenssionTypeList = response.data.map((el) => ({
            label: el.name,
            value: el.id,
          }));
        });
        runInAction(() => {
          this.selectedOverviewTrendCardDimenssionType = response.data[0].name;
          this.selectedOverviewSegmentsCardFilter = response.data[0].name;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isDimensionQuestionsPending = false;
    }
  };

  getSurveyQuestionsList = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isSurveyQuestionsListPending = true;
      this.surveyQuestionsList = [];
      const response = await useGetQuestionBySurveyId({ survey_id, attributes: filters });
      if (
        (response.status === 200 || response.status === 201) &&
        response.data &&
        response.data.length > 0
      ) {
        runInAction(() => {
          this.surveyQuestionsList = response.data.map((el) => ({
            label: el.title,
            value: el.id,
          }));
        });
        runInAction(() => {
          this.questionSelectedOpenEndedCard = response.data[0].id;
        });
        runInAction(() => {
          this.openEndedTopics = response.data.topics;
        });
        runInAction(() => {
          this.openEndedTopicsValid = response.data.validTopics;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isSurveyQuestionsListPending = false;
    }
  };

  getSurveyResponseCount = async (
    id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      this.isSurveyResponseCountPending = true;

      // it's crutch, remove it on refactor
      if (typeof this.overviewBenchmark !== "number" && this.activeTab !== DASHBOARD_MENU_VALUES.trends) {
        await this.getBenchmarkList(id)
      }

      const requestData = {
        survey_id: this.activeTab !== DASHBOARD_MENU_VALUES.trends ? id : undefined,
        project_id: this.activeTab === DASHBOARD_MENU_VALUES.trends ? id : undefined,
        viewType: this.role === ROLE_LIST.MANAGER.value ? this.secondaryRole : this.role,
        benchmarkType: this.overviewBenchmark,
        attributes: filters,
      };

      const response = await getSurveyResponseCountAsync(requestData);

      runInAction(() => {
        this.recipientsCount = response.recepients;
        this.respondentCount = response.respondents;

        if (typeof this.selectedSurvey?.threshold === "number") {
          this.isSurveyHaveResponseCount = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold
          );

          this.isDisplayOpenEndedCard = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type === "open-end"
          );

          

          this.isOveralScoreEmpty = response.questions.every((el) => !el.isOverall || el.totalResponces === 0);
          this.isENPSEmpty = response.questions.every((el) => el.type.toLowerCase() !== "enps" || el.totalResponces === 0)
          this.isDimensionsEmpty = response.questions.every((el) => (el.type !== "rating" && el.type !== "checkbox") || el.totalResponces === 0)
          this.isSegmentsEmpty = response.questions.every((el) => (el.type !== "rating" && el.type !== "checkbox" && el.type.toLowerCase() !== "enps") || el.totalResponces === 0)

          this.isResponseScoredTabDisplay = response.questions.some(
            (el) =>
              el.totalResponces >= this.selectedSurvey.threshold &&
              (el.type === "rating" || el.type === "enps")
          );

          this.isResponseOPenEndedTabDisplay = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type === "open-end"
          );

          this.isResponseOthersTabDisplay = response.questions.some(
            (el) =>
              el.totalResponces >= this.selectedSurvey.threshold &&
              el.type !== "open-end" &&
              el.type !== "rating" &&
              el.type !== "enps"
          );

          this.isNotOnlyOpenendQuestions = response.questions.some(
            (el) => el.totalResponces >= this.selectedSurvey.threshold && el.type !== "open-end"
          );
        } else {
          this.isDisplayTrends = response.questions.some((el) => el.type !== "open-end");
          this.isSurveyHaveResponseCount = false;
          this.isDisplayOpenEndedCard = false;
        }
        this.isSurveyResponseCountPending = false;
      });
    } catch (err) {
      this.isOveralScoreEmpty = false;
      this.isENPSEmpty = false;
      this.isSurveyResponseCountPending = false;
      console.error(err);
    }
  };

  getBenchmarkList = async (survey_id: number) => {
    try {
      this.isBenchmarkListPending = true;
      const response = await useGetBenchmarkList(survey_id);
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.benchmarkFilterList = response.data.map((el) => ({ value: el.id, label: el.name }));
          this.overviewBenchmark =
            response.data && response.data.length > 0 ? response.data[0].id : null;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      this.isBenchmarkListPending = false;
    }
  };
}

const store = new Store();

export default store;
