import http from "../https";
import { USE_DASHBOARD_API_ATTRIBUTES } from "constants/config";

import { TDashboardGlobalFilter } from "types/dashboard";

export const getSurveyAttributeAsync = async (
  workspace_id: number,
  entity_id: number,
  project_id: number,
  survey_id: number,
): Promise<TDashboardGlobalFilter[]> => {
  let response;

  if (USE_DASHBOARD_API_ATTRIBUTES) {
    response = await
      http.dashboardAPIClient.post(`/dashboard/dashboard/getAttributes`, {
        workspace_id,
        entity_id,
        project_id,
        survey_id,
      });
  } else {
    response = await http.get(
      `/dashboard/attributes`,
      {},
      {
        params: {
          survey_id,
        },
      },
    );

  }

  if ([200, 201].includes(response.status)) {
    return response.data;
  }

  throw new Error(`Failed to fetch attributes (HTTP status code: ${response.status})`);
};
