export const API_URL = window["_env_"]?.REACT_APP_BASE_API ?? process.env.REACT_APP_BASE_API
export const ISAPI_TEMPLATE_GO = true;
export const API_TEMPLATE_URL_GO = window["_env_"]?.REACT_APP_BASE_API_GO ?? process.env.REACT_APP_BASE_API_GO;
export const USE_DASHBOARD_API = getVariable("REACT_APP_USE_DASHBOARD_API") === "true";
export const USE_DASHBOARD_API_ATTRIBUTES = getVariable("REACT_APP_USE_DASHBOARD_API_ATTRIBUTES") === "true";

function getVariable(variableName: string) {
    const localStorageValue = localStorage.getItem(variableName);
    if (localStorageValue === null) {
        return process.env[variableName];
    }
    return localStorageValue;
}
