import { USE_DASHBOARD_API } from "constants/config";
import http from "../https";

type TRequestBody = {
  workspace_id: number;
  entity_id: number;
  project_id: number;
  survey_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  viewType: string;
  rowSettings: {
    overalScore: boolean;
    eNPS: boolean;
    questions: number[];
  };
};

export const useGetDashboardHotspots = (requestData: TRequestBody) => {
  if (USE_DASHBOARD_API) {
    return http.dashboardAPIClient.post(`/dashboard/dashboard/hotspots`, requestData);
  } else {
    return http.post(`/Dashboard/hotspot`, requestData);
  }
}
