import { FC, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, IconButton, InputAdornment, Modal, TextField } from "@mui/material";

import store from "./store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "components/button/Button";
import ExportRecipientsTable from "./ExportRecipientsTable";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { ClearIcon } from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";

type ExportRecipietnsProps = {};

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const ExportRecipietns: FC<ExportRecipietnsProps> = observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [showUploadProcess, setShowUploadProcess] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fileInputRef = useRef(null);
  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  useEffect(() => {
    if (showUploadProcess) {
      setTimeout(() => {
        setShowUploadProcess(false);
      }, 5000);
    }
  }, [showUploadProcess]);

  useEffect(() => {
    if (fileName) {
      setShowUploadProcess(true);
    }
  }, [fileName]);

  const handleFileSelect = () => {
    setError(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    fileInputRef.current.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    handleFile(file);
  };

  const handleFile = (file: File | null) => {
    if (file && !file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      setError("Oops! Wrong file format. Please upload a .xlsx file.");
      setFileName(null);
    } else if (file?.size > MAX_FILE_SIZE) {
      setError("File size exceeds the 5MB limit.");
      setFileName(null);
    } else {
      setError(null);
      setFileName(file.name);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files?.[0];
    handleFile(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onCloseModal = () => {
    setShowModal(false);
    setError(null);
    setFileName(null);
    setShowUploadProcess(false);
  };

  const handleBulkDelete = () => {
    if (store.exportRecipientsList.length > 0) {
      setShowModal(true);
    };
  };

  return (
    <>
      <Container>
        {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) ? (
          <>
            <PSDTitle id="Survey_EditCreate_Recipietns_Import_SurveyName">{store.Survey?.name}</PSDTitle>
            <HeaderContainer
              id="Survey_EditCreate_Recipietns_Export_Title"
              style={{
                borderBottom: "2px solid ",
                borderColor: "var(--colorNeutralBackground3)",
                paddingBottom: "8px",
              }}
            >
              <PSDHeader>
                {store.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones
                  ? "Milestone Recipients"
                  : "Survey Recipients"}
              </PSDHeader>
              <CTAWrapper 
                onClick={handleBulkDelete} 
                isEnabled={store.exportRecipientsList.length > 0}
              >
                Bulk Delete Recipient
              </CTAWrapper>
            </HeaderContainer>

            <PSDSettingsWrapper>
              <Box>
                <PSDStyledSearchInput
                  id="Survey_EditCreate_Recipietns_Export_SearchInput"
                  size="small"
                  placeholder="Search for name/email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="Survey_EditCreate_Recipietns_Export_SearchInput_Button"
                          onClick={() => {
                            store.getAllRecipients();
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        {store.exportSearchValue && (
                          <IconButton
                            id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                            onClick={() => {
                              store.exportSearchValue = "";
                              store.getAllRecipients();
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={store.exportSearchValue}
                  onKeyDown={store.keyPressExport}
                  onChange={(e) => store.searchExportRecipients(e.target.value)}
                />
              </Box>
              <PSDExportButton
                id="Survey_EditCreate_Recipietns_Export_ExportAllRecipients_Button"
                variant="text"
                disabled={!store.exportRecipientsList.length}
                onClick={() => {
                  store.saveExcel();
                }}
              >
                <ExportButtonWrapper isEnabled={store.exportRecipientsList.length > 0}>
                  <img alt="excel" width={18} height={18} src={"/assets/icons/excel.svg"} />
                  <label>Export all recipients [.xlsx ]</label>
                </ExportButtonWrapper>
              </PSDExportButton>
            </PSDSettingsWrapper>
          </>
        ) : (
          <>
            <Header id="Survey_EditCreate_Recipietns_Export_Title">
              {store.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones
                ? "Milestone Recipients"
                : "Survey Recipients"}
            </Header>

            <SettingsWrapper>
              <ExportButton
                id="Survey_EditCreate_Recipietns_Export_ExportAllRecipients_Button"
                variant="outlined"
                disabled={!store.exportRecipientsList.length}
                onClick={() => {
                  store.saveExcel();
                }}
              >
                Export all recipients [.xlsx ]
              </ExportButton>
              <Box>
                <StyledSearchInput
                  id="Survey_EditCreate_Recipietns_Export_SearchInput"
                  style={{ width: "200px", margin: "5px 5px 5px 12px" }}
                  size="small"
                  placeholder="Search for name/email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          id="Survey_EditCreate_Recipietns_Export_SearchInput_Button"
                          onClick={() => {
                            store.getAllRecipients();
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                        {store.exportSearchValue && (
                          <IconButton
                            id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                            onClick={() => {
                              store.exportSearchValue = "";
                              store.getAllRecipients();
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={store.exportSearchValue}
                  onKeyDown={store.keyPressExport}
                  onChange={(e) => store.searchExportRecipients(e.target.value)}
                />
              </Box>
            </SettingsWrapper>
          </>
        )}

        {!!store.resultexportSearchValue && (
          <ResultSearch id="Survey_EditCreate_Recipietns_Export_ResultSearch">
            {!store.exportRecipientsList.length ? (
              <SearchText id="Survey_EditCreate_Recipietns_Export_ResultSearch_Text">
                "
                <SearchTextValue id="Survey_EditCreate_Recipietns_Export_ResultSearch_Name">
                  {store.resultexportSearchValue}
                </SearchTextValue>
                " No employees match the provided details. Please try using a different search
                keyword.
              </SearchText>
            ) : (
              <SearchText id="Survey_EditCreate_Recipietns_Export_ResultSearch_Text">
                "
                <SearchTextValue id="Survey_EditCreate_Recipietns_Export_ResultSearch_Name">
                  {store.resultexportSearchValue}
                </SearchTextValue>
                " found on the employee list.
              </SearchText>
            )}

            <RemoveSearch>
              <CustomButton
                id="Survey_EditCreate_Recipietns_Export_ResultSearch_Remove_Button"
                onClick={() => {
                  store.setData("resultexportSearchValue", "");
                  store.setData("exportSearchValue", "");
                  store.getAllRecipients();
                }}
              >
                <ClearIcon /> Remove search
              </CustomButton>
            </RemoveSearch>
          </ResultSearch>
        )}

        <ExportRecipientsTable />
      </Container>

      <PSDModalDialog
        onClose={onCloseModal}
        aria-labelledby="customized-dialog-title"
        fullWidth={true}
        open={showModal}
      >
        <ContentWrapper>
          <ModalHeader>
            <ModalTitle>Bulk delete recipients</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <DescriptionWrapper>
              <p>
                Please refer to the Excel template for guidance on bulk recipients deletion. Once
                finished, save the file in .xlsx format and upload it.
              </p>
              <SubDescriptionWrapper>
                <img alt="notice" width={12} height={14} src={"/assets/icons/notice.svg"} />
                <p>
                  Deleted recipients will be removed from unscheduled and upcoming surveys. Running
                  and completed surveys will not be affected.
                </p>
              </SubDescriptionWrapper>
              <DownloadWrapper>
                <img alt="download" width={12} height={14} src={"/assets/icons/download.svg"} />
                <CTAWrapper>Download Excel template</CTAWrapper>
              </DownloadWrapper>
            </DescriptionWrapper>
            <UploadWrapper
              fileName={fileName}
              showUploadProcess={showUploadProcess}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              {fileName && !showUploadProcess ? (
                <UploadLabel>File uploaded successfully</UploadLabel>
              ) : (
                <UploadLabel>Upload file</UploadLabel>
              )}

              <UploadInformation>
                {error ? (
                  <>
                    <img
                      alt="error"
                      width={27}
                      height={33}
                      src={"/assets/icons/sd-card-alert.svg"}
                    />
                    <label>
                      Drag and drop your file here, or click 'Browse file' to upload (.xlsx).
                      Maximum file size: 5MB
                    </label>
                  </>
                ) : showUploadProcess ? (
                  <>
                    <img
                      alt="uploading"
                      width={40}
                      height={40}
                      src={"/assets/icons/upload-download.svg"}
                    />
                    <label>
                      You can continue using the platform while the file is being processed in the
                      background.
                    </label>
                  </>
                ) : fileName ? (
                  <>
                    <img alt="upload" width={27} height={33} src={"/assets/icons/task-green.svg"} />
                    <label>{fileName}</label>
                  </>
                ) : (
                  <>
                    <img
                      alt="upload"
                      width={27}
                      height={33}
                      src={"/assets/icons/upload-file.svg"}
                    />
                    <label>
                      Drag and drop your file here, or click 'Browse file' to upload (.xlsx).
                      Maximum file size: 5MB
                    </label>
                  </>
                )}
              </UploadInformation>

              <input
                type="file"
                ref={fileInputRef}
                accept=".xls,.xlsx"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              {!fileName && !showUploadProcess && (
                <CustomButton
                  name="browsebutton"
                  color="primary"
                  variant="outlined"
                  style={{ width: "fit-content" }}
                  onClick={handleFileSelect}
                >
                  Browse files
                </CustomButton>
              )}
            </UploadWrapper>

            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ModalBody>

          {fileName && !showUploadProcess && (
            <>
              <SummaryTitle>Import summary</SummaryTitle>

              <SummaryWrapper>
                <RecipientsNumber>11</RecipientsNumber>
                <label>recipients removed</label>
              </SummaryWrapper>
            </>
          )}

          <ButtonWrapper>
            <CustomButton
              name="AlertButtonYes"
              color="primary"
              variant="contained"
              disabled={!fileName || showUploadProcess}
              onClick={onCloseModal}
            >
              Delete Recipients
            </CustomButton>
            <CustomButton
              name="browsebutton"
              variant="outlined"
              style={{
                width: "fit-content",
                borderColor: "var(--colorNeutralForeground5)",
                color: "var(--colorNeutralForeground2)",
              }}
              onClick={onCloseModal}
            >
              Cancel
            </CustomButton>
          </ButtonWrapper>
        </ContentWrapper>
      </PSDModalDialog>
    </>
  );
});

export default ExportRecipietns;

const Container = styled.div`
  width: 50%;
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralForeground4);
  padding: 74px 62px;

  @media (max-width: 1450px) {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: baseline;
`;

const PSDHeader = styled.h6`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Header = styled.h6`
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  text-transform: uppercase;
  margin: 0px;
`;

const CTAWrapper = styled.a<{ isEnabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ isEnabled=true }) =>
    isEnabled ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground5)"};
  
  cursor: ${({ isEnabled=true }) =>
    isEnabled ? "pointer" : "not-allowed"};
`;

const PSDSettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 32px;
  padding-bottom: 6px;
`;

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ResultSearch = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const ExportButton = styled(CustomButton)`
  height: 34px !important;
  border: 1px solid var(--colorNeutralForeground6) !important;
  margin-right: 20px !important;
  &:disabled {
    color: var(--colorBrandForeground2) !important;
  }
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 246px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0 !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 7px 11px !important;
    border-radius: 2px !important;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const PSDExportButton = styled(CustomButton)`
  height: 34px !important;
  margin-right: 20px !important;
  margin-left: auto !important;
  &:disabled {
    color: var(--colorBrandForeground2) !important;
  }
`;

const ExportButtonWrapper = styled.div<{ isEnabled?: boolean }>`
  display: flex;
  column-gap: 4px;

  label {
    color: ${({ isEnabled=true }) =>
      isEnabled ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground5)"};
  }
`;

const PSDStyledSearchInput = styled(TextField)`
  width: 400px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0 !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 7px 11px !important;
    border-radius: 2px !important;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const ContentWrapper = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 52px !important;
`;

const PSDModalDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 840px !important;
  }
`

const ModalHeader = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const ModalTitle = styled.h1`
  font-weight: 500;
  font-size: 24px;
  margin: 0px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const SummaryTitle = styled.label`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;
const SummaryWrapper = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

const RecipientsNumber = styled.label`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 14px;

  p {
    color: var(--colorNeutralForeground2);
  }
`;

const SubDescriptionWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  padding: 8px;
  background-color: var(--colorPalleteLightBlue);
  border-radius: 4px;
`;

const DownloadWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

const UploadWrapper = styled.div<{ fileName: string; showUploadProcess: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 30px 20px 30px;
  border-radius: 8px;
  border-width: 1px;
  border-style: ${({ fileName, showUploadProcess }) =>
    fileName && !showUploadProcess ? "solid" : "dashed"};
  border-color: var(--graySelectBoxBorder);

  &:hover {
    background-color: ${({ fileName, showUploadProcess }) =>
      !fileName && !showUploadProcess && "var(--colorNeutralForeground4)"};
  }
`;

const UploadLabel = styled.label`
  font-weight: 500;
  font-size: 16px;
`;

const UploadInformation = styled.div`
  display: flex;
  column-gap: 8px;
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  color: red;
`;

const PSDTitle = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  padding-bottom: 40px;
`;
