import { observer } from "mobx-react";
import { Transition } from "./TransitionPopup";
import { SelectChangeEvent } from "@mui/material";
import { FC, useEffect, useState } from "react";

import store from "../store";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import topStore from "../../store";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ClearIcon from "@mui/icons-material/Clear";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import SettingsList from "./SettingsList";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

type SetUpSegmentsPopupProps = {};

const SetUpSegmentsPopup: FC<SetUpSegmentsPopupProps> = observer(() => {
  const [localSelectedSegments, setLocalSelectedSegments] = useState([
    { attribute: "", mode: "", value: null },
  ]);

  useEffect(() => {
    if (topStore.activeTab === "hotspots") {
      store.setData([], "selectedSegments");
      setLocalSelectedSegments([{ attribute: "", mode: "", value: null }]);
    }
  }, [topStore.selectedSurvey, topStore.activeTab]);

  useEffect(() => {
    if (store.isOpenSetupSegmnetsPopup && store.selectedSegments.length > 0)
      setLocalSelectedSegments(store.selectedSegments);
  }, [store.isOpenSetupSegmnetsPopup]);

  const changeSegments = (chengedindex: number, field: string, value: any) => {
    const newSegmentsList = localSelectedSegments.map((row, index) => {
      if (index === chengedindex) {
        return {
          ...row,
          [field]: value,
        };
      } else return row;
    });
    setLocalSelectedSegments(newSegmentsList);
  };

  const addNewAttribute = () => {
    const newAttribute = { attribute: "", mode: "", value: null };
    setLocalSelectedSegments((prev) => [...prev, newAttribute]);
  };

  const clearLastAttribute = (chengedindex: number) => {
    const newSegmentsList = localSelectedSegments.map((row, index) => {
      if (index === chengedindex) {
        return {
          attribute: "",
          mode: "",
          value: "",
        };
      } else return row;
    });
    setLocalSelectedSegments(newSegmentsList);
  };

  useEffect(() => {
    if (store.isOpenSetupSegmnetsPopup && topStore.selectedSurvey) {
      store.getSurveyAttributes(StoreLayout.currentWorkspaceId, StoreLayout.currentEntityId, topStore.selectedProject.id, topStore.selectedSurvey.value);
    }
  }, [topStore.selectedSurvey, store.isOpenSetupSegmnetsPopup]);

  useEffect(() => {
    if (
      localSelectedSegments[localSelectedSegments.length - 1].value &&
      !localSelectedSegments.some((el) => el.mode === "multiple")
    ) {
      addNewAttribute();
    } else return;
  }, [localSelectedSegments]);

  const handleModeSelect = (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => {
    if (e.target.value === "one" && row.mode === "multiple") {
      setLocalSelectedSegments((prev) =>
        prev.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              value: row.value?.length > 0 ? row.value[0] : "",
              mode: e.target.value,
            };
          } else return row;
        })
      );
    } else if (e.target.value === "multiple" && row.mode === "one") {
      setLocalSelectedSegments((prev) =>
        prev
          .map((row, index) => {
            if (index === rowIndex) {
              return {
                ...row,
                value: row.value ? [row.value] : [],
                mode: e.target.value,
              };
            } else return row;
          })
          .filter((el, index) => index <= rowIndex)
      );
    } else changeSegments(rowIndex, "mode", e.target.value);
  };

  const handleAttributeSelect = (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => {
    setLocalSelectedSegments((prev) =>
      prev
        .map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              attribute: e.target.value,
              mode: "",
              value: null,
            };
          } else return row;
        })
        .filter((el, index) => index <= rowIndex)
    );
  };

  const clearAllAttribute = () => {
    setLocalSelectedSegments([{ attribute: "", mode: "", value: null }]);
    store.setData([], "selectedSegments");
  };

  return (
    <StyledDialog open={store.isOpenSetupSegmnetsPopup} TransitionComponent={Transition}>
      <StyledDialogContent>
        <CloseBtnWrapper>
          <CloseButton onClick={() => store.setData(false, "isOpenSetupSegmnetsPopup")}>
            <ClearIcon />
          </CloseButton>
        </CloseBtnWrapper>
        <DialogTitleWrapper>
          <DialogTitle>Select segments to analyze</DialogTitle>
          {localSelectedSegments.length > 0 && localSelectedSegments[0].value && (
            <ClearAllButton variant="text" onClick={clearAllAttribute}>
              Clear and start over
            </ClearAllButton>
          )}
        </DialogTitleWrapper>
        <SubTitle>Within all respondents</SubTitle>
        <SettingsList
          localSelectedSegments={localSelectedSegments}
          handleModeSelect={handleModeSelect}
          handleAttributeSelect={handleAttributeSelect}
          changeSegments={changeSegments}
          clearLastAttribute={clearLastAttribute}
        />
      </StyledDialogContent>
      <DialogActions>
        <ViewScoresButton
          variant="contained"
          disabled={!localSelectedSegments[0]?.value}
          onClick={() => {
            store.setData(localSelectedSegments, "selectedSegments");
            store.setData(false, "isOpenSetupSegmnetsPopup");
          }}
        >
          View segments on chart
        </ViewScoresButton>
        <BottomDescriptionContainer>
          <InfoRoundedIcon />
          <BottomDescriptionText>
            Loading scores on the chart may experience delays when dealing with numerous data
            points. However, you can conveniently download the .xlsx file while waiting for the
            chart to load.
          </BottomDescriptionText>
        </BottomDescriptionContainer>
      </DialogActions>
    </StyledDialog>
  );
});

export default SetUpSegmentsPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 48px !important;
  }

  .MuiPaper-root {
    height: 100% !important;
    max-height: none !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .MuiDialog-container {
    justify-content: flex-start !important;
  }

  .MuiDialogActions-root {
    padding: 24px 48px !important;
    flex-direction: column;
    box-shadow: 0px -2px 6px 0px var(--colorShadowInverted1);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  color: var(--colorBrandForeground1);

  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ViewScoresButton = styled(Button)`
  width: 100%;
  text-transform: none !important;

  &:disabled {
    background-color: var(--colorNeutralBackground5) !important;
    color: var(--colorNeutralForeground2) !important;
  }
`;

const BottomDescriptionContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 24px 1fr;
  margin-top: 16px;
  column-gap: 4px;

  svg {
    font-size: 20px;
    color: var(--colorPaletteVioletBackground2);
  }
`;

const BottomDescriptionText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

const SubTitle = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-top: 8px;
`;

const DialogTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ClearAllButton = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
`;
